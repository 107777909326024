<template>
	<div>
		<div class="pt-7 container-fluid">
			<b-card class="mb-4">
				<b-row class="filterSection">
					<b-col md="12" class="mb-4">
						<b-form role="form" @submit.prevent="addItem" v-loading="loading">
							<b-input-group>
								<b-form-textarea
									v-model="nameList"
									size="sm"
									max-rows="8"
									placeholder="Hastalıkları alt alta girebilirsiniz"
								></b-form-textarea>
								<template #append>
									<b-button type="submit" :disabled="loading" variant="primary">Ekle</b-button>
								</template>
							</b-input-group>
						</b-form>
					</b-col>
				</b-row>

				<b-form-input v-model="filter.keyword" type="text" debounce="500" placeholder="Arama"></b-form-input>
			</b-card>

			<b-card v-loading="loading" v-if="records" class="p-4">
				<b-list-group>
					<b-list-group-item v-for="(item, index) in records" :key="index">
						<b-row>
							<b-col md="2">
								<b-button size="sm" pill variant="danger" @click="deleteItem(item._id)"><i class="fal fa-trash-alt"></i></b-button>
								<b-button size="sm" pill variant="primary" @click="editItem(item._id)"><i class="fal fa-edit"></i></b-button>
							</b-col>
							<b-col md="4">
								{{ getLangItem(item.name) }}
							</b-col>
							<b-col md="3" class="text-center">
                                <i class="fas fa-viruses text-primary" v-if="item.symptoms.length>0"></i>
								<b-badge variant="info" v-for="symptom in item.symptoms" :key="symptom" class="ml-1">
								{{ symptom|showSymptom }}
								</b-badge>
							</b-col>
							<b-col md="3" class="text-center">
                                <i class="fas fa-building text-primary" v-if="item.branch.length>0"></i>
								<b-badge variant="info" v-for="branch in item.branch" :key="branch._id" class="ml-1">
								{{ branch|showBranch }}
								</b-badge>
							</b-col>
						</b-row>
					</b-list-group-item>
				</b-list-group>
				<hr />
				<b-pagination v-model="currentPage" :total-rows="stats.totalRecord" :per-page="20"></b-pagination>
			</b-card>
		</div>
		<b-modal id="editmodal">
			<b-form role="form" @submit.prevent="saveItem">
				<b-input-group>
					<language-input style="width: 100%" v-model="record.name" />
					<b-button type="submit" variant="primary" style="position: absolute; left: -9999px">Submit</b-button>
					<hr />
				</b-input-group>
				<div class="mb-3">
					<div class="mb-2">Semtoplar</div>
					<div class="mt-2">
                        <base-input>
                            <el-select v-model="record.symptoms" :placeholder="$t('general.select')" multiple :rules="'required'">
                                <el-option v-for="(item, key) in symptomRecords" :key="key" :label="getLangItem(item.name)" :value="item._id"></el-option>
                            </el-select>
                        </base-input>
                    </div>
				</div>
				<div class="mb-3">
					<div class="mb-2">Branşlar</div>
					<div class="mt-2">
                        <base-input>
                            <el-select v-model="record.branch" :placeholder="$t('general.select')" multiple :multiple-limit="5" :rules="'required'">
                                <el-option v-for="(item, key) in branchList" :key="key" :label="item.name[$i18n.locale]" :value="item._id"></el-option>
                            </el-select>
                        </base-input>
                    </div>
				</div>
				<div class="mb-3">
					<div class="mb-2">Doktorlar</div>
					<div class="mt-2">
                        <base-input>
                            <el-select v-model="record.doctor" :placeholder="$t('general.select')" multiple :multiple-limit="5" :rules="'required'">
                                <el-option v-for="(item, key) in doctors" :key="item._id" :label="`${item.title}${item.name} - ${item.branch[0].name[userLang]}`" :value="item._id"></el-option>
                            </el-select>
                        </base-input>
                    </div>
				</div>

			</b-form>
			<template #modal-footer="{hide}">
				<div class="w-100">
					<b-button variant="primary" size="sm" class="float-right" @click="saveItem"> {{ $t('general.save') }} </b-button>
					<b-button size="sm" variant="danger" @click="hide('editmodal')"> {{ $t('general.cancel') }} </b-button>
				</div>
			</template>
		</b-modal>
	</div>
</template>

<script>
import _ from 'lodash'
import {handleError} from '@/utils/func'
import {mapGetters, mapState} from 'vuex'
let self;

export default {
	computed: {
        ...mapGetters({
            branchList: 'getBranchList',
            doctors: 'getDoctors',
        }),
        ...mapState('symptom', {
            'symptomRecords': 'records',
        }),
        ...mapState(['userLang']),
		...mapState('disease', ['records', 'record', 'filter', 'stats', 'page']),
		currentPage: {
			get() {
				return _.clone(this.page)
			},
			set(val) {
				this.$store.commit('disease/setPage', val)
			}
		}
	},
	data() {
		return {
			loading: false,
			nameList: ''
		}
	},
	methods: {
		async getData() {
			await this.$store.dispatch('disease/list')
		},
		async addItem() {
			const result = await this.$store.dispatch('disease/create', this.nameList)
			this.$notify({type: 'success', message: `${result.added} öğe eklendi. ${result.notadded} öğe eklenemedi`})
		},
		async editItem(_id) {
			await this.$store.dispatch('disease/get', _id)
			this.$bvModal.show('editmodal')
		},
		async saveItem() {
			await this.$store.dispatch('disease/update')
			this.$bvModal.hide('editmodal')
			this.$notify({type: 'success', message: this.$i18n.t('success.positive')})
		},
		async deleteItem(_id) {
			const result = await this.$swal({
				title: this.$i18n.t('quest.sure'),
				text: this.$i18n.t('quest.delete_info'),
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#f5365c',
				confirmButtonText: this.$i18n.t('quest.delete'),
				cancelButtonText: this.$i18n.t('quest.cancel')
			})
			if (result.value) {
				this.loading = true
				try {
					await this.$store.dispatch('disease/delete', _id)
					this.loading = false
					this.$notify({type: 'success', message: this.$i18n.t('success.positive')})
				} catch (err) {
					this.loading = false
					handleError(this, err)
				}
			}
		}
	},
    filters:{
        showSymptom(value) {
            let lang = self.$i18n.locale;
            let symptom = _.find(self.symptomRecords, item => {
                return value == item._id;
            });

            if(symptom){
                let symptomName = _.find(symptom.name, item => {
                    return item.lang == lang;
                })
    
                return symptomName?symptomName.value:_.values(symptom.name)[0].value;
            }else{
                return '';
            }

        },
        showBranch(value) {
            let lang = self.$i18n.locale;
            let branch = _.find(self.branchList, item => {
                return value == item._id;
            });
            if(branch){
                return branch.name[lang]!==undefined ? branch.name[lang] : (branch.name['tr']!==undefined ? branch.name['tr'] : 'none');
            }else{
                return '';
            }

        }
    },
	async created() {
        self = this;
		await this.$store.dispatch('disease/list')
        await this.$store.dispatch('symptom/list', 10000)

        self.$store.dispatch('DOCTOR_REQUEST', { pagination: { limit: 1000 }, fields: '_id title name branch', populate: 'none' });
	},

	watch: {
		filter: {
			deep: true,
			handler: _.debounce(function (newVal) {
				this.$store.dispatch('disease/list')
			}, 500)
		},
		page() {
			this.$store.dispatch('disease/list')
		}
	}
}
</script>
